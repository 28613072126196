import React from 'react';
import { Box, Image, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

import CustomerSupportLayout from './_layout';
import LocationArrowIcon from '../../icons/location-arrow.svg';

const ContactUsConfirmationPage = props => {
  const { t } = useTranslation();

  return (
    <CustomerSupportLayout
      subtitle={t('support.contact_us.menu.title')}
      {...props}
    >
      <Box pt="120px" width="74px" mx="auto">
        <Image mx="auto" src={LocationArrowIcon} />{' '}
      </Box>
      <Text pt="43px" fontSize="35px" textAlign="center" color="blues.peacock">
        {t('support.contact_us.confirmation.title')}
      </Text>
      <Text py="56px" fontSize="20px" textAlign="center" color="marine">
        {t('support.contact_us.confirmation.subtitle')}
      </Text>
    </CustomerSupportLayout>
  );
};

export default ContactUsConfirmationPage;
